import loadable from '@loadable/component';
import { PageLoading } from "@tawn33y/gigsflow-core";

const NotFound = loadable(() => import('@tawn33y/gigsflow-core/dist/pages/NotFound/NotFound'), {
  resolveComponent: (components) => components.NotFound,
  fallback: (
    <PageLoading
      title="Page Not Found"
      description="Sorry, the page you are looking for doesn't exist or has been moved"
    />
  ),
});

export default function Custom404() {
  return <NotFound />
}
